import React,{ useEffect, useState} from 'react';
import $ from 'jquery';
import 'magnific-popup'
import Slider from "react-slick";

// img
import img1 from '../../assets/nft/1.jpg'
import img2 from '../../assets/nft/2.jpg'
import img3 from '../../assets/nft/3.jpg'
import img4 from '../../assets/nft/4.jpg'
import img5 from '../../assets/nft/5.jpg'
import img6 from '../../assets/nft/6.jpg'

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button type="button" className={ className  } onClick={ onClick }><i className="fa-solid fa-angle-left"></i></button>
    );
}
function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <button type="button" className={ className  } onClick={ onClick }><i className="fa-solid fa-angle-right"></i></button>
    );
}


function GalleryArea() {
  // const settings1 ={
  // centerMode: true,
	// autoplay: false,
	// infinite: true,
	// speed: 500,
	// centerPadding: '0',
	// arrows: false,
	// slidesToShow: 1,
	// asNavFor: '.gallery-nav-active',
	// responsive: [
	// 	{
	// 		breakpoint: 1800,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 			infinite: true,
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 1500,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 			centerPadding: '30px',
	// 			infinite: true,
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 1200,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 			centerPadding: '50px',
	// 			infinite: true,
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 992,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			centerPadding: '0',
	// 			slidesToScroll: 1
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 767,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 			centerPadding: '0px',
	// 			arrows: false,
	// 		}
	// 	},
	// 	{
	// 		breakpoint: 575,
	// 		settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 			centerPadding: '0px',
	// 			arrows: false,
	// 		}
	// 	},
	// ]
  // }
const settings = {
	dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
}

  return (
	<div className="slider-container">
    	<Slider   {...settings} >
                <div >
                  <img src={img1} className='w-96 h-50' alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

				<div >
                  <img src={img2} alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

				<div >
                  <img src={img3} alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

				<div >
                  <img src={img4} alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

				<div >
                  <img src={img5} alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

				<div >
                  <img src={img6} alt="" />
                  {/* <a href="/https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video">
                    <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                      <circle className="circle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                      <polygon className="triangle" fill="none" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                    </svg>
                  </a> */}
                </div>

		</Slider>
	</div>
  )
}

export default GalleryArea