import React from 'react'


import MainSlider from "../home/components/main/MainSlider";
import Brand from "../home/components/main/Brand";
import AboutArea from "../home/components/main/AboutArea";
import GalleryArea from '../home/components/main/GalleryArea';
import Token from "../home/components/main/Token";
import Hero from "../home/components/main/Hero";
import Ecosystem from "../home/components/main/Ecosystem";
import KatanaArea from "../home/components/main/KatanaArea";
import Partners from "../home/components/main/Partners";
import Roadmap from "../home/components/main/Roadmap";
import TeamArea from "../home/components/main/TeamArea";
import BlogArea from "../home/components/main/BlogArea";
import PurchaseArea from "../home/components/main/PurchaseArea";
import Contact from "../home/components/main/Contact";
import FaqSection from "../home/components/faq/FaqSection";

import Header from "../home/components/Header";
import Footer from "../home/components/Footer";

function Home() {
  return (
	<>
	<Header />
	  <main>
	   	<MainSlider/>
		<GalleryArea/>

	   {/* <Brand/> */}
	   <AboutArea/>
	   
	   {/* <Token/> */}
	   {/* <Hero/> */}
	   {/* <Ecosystem/> */}
	   {/* <KatanaArea/> */}
	   {/* <Partners/> */}
	   <Roadmap/>
	   {/* <TeamArea/> */}
	   {/* <BlogArea/> */}
	   {/* <PurchaseArea/> */}
	   <FaqSection/>
	   <Contact/>

	  </main>
	  {/* <Footer /> */}
	</>
  )
}

export default Home