import React from 'react';
import btc from '../../assets/banner.jpg'
import  opensea from '../../assets/opensea.png'
import binance from '../../assets/binance.png'
import  cap from '../../assets/nft/MARKET-CAP.png'

function AboutArea() {
  
  return (
	<section id="about-us" className="about-area about-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6">
              <div className="about-title">
                <h2 className="title">
                  The global NFT market cap now a day is $72.78 Billion, a +2.45% change in the last 24 hours.
                </h2>
                <div className="about-content">
                  {/* <h6 className="rotate-content">Phobos NFT-GAMING</h6> */}
                  {/* <h2 className="title">Join Our DISCORD To Get <span>Your WHITELIST Spot</span></h2> */}
                  <p>
                    Total NFT trading volume in the last day is at $7.24 Million. Forbes is now tracking 4,621 NFT collections with a total trading volume of $7.24 Million. Bored Ape Yacht Club dominance is at +0.42% and CryptoPunks dominance is at +1.03%.                 
                  </p>
                  
                    {/* <h4 className="small-title">NFT Minting Price</h4>
                    <h6 className="price">1 <span> NFT PUBLIC PRICE  = 0.1 ETH </span> / MAX 9 NFT PER WALLET</h6>
                    <h6 className="price">1 <span> NFT WHITELIST PRICE = 0.07 ETH </span> / MAX 3 NFT PER WALLET</h6> */}
                </div>
                  
                <div className="about-btn-wrap">

                  <a href="https://www.binance.com/en/nft/home" className="btn discord" target="_blank">
                    <img style={{borderRadius: "50%" , marginRight: "5px"}} width={50}  height={50} src={opensea} alt="" />
                    Open C Io
                   </a>
                  <a href="https://www.binance.com/en/nft/home" className="btn discord" target="_blank">
                   <img style={{borderRadius: "50%" , marginRight: "5px"}} width={50}  height={50} src={binance} alt="" />
                   Binance NFT
                   </a>

                   <a href="https://www.forbes.com/digital-assets/nft-prices/?sh=73f69c436dfb" className="btn discord" target="_blank">
                    <img style={{borderRadius: "50%" , marginRight: "5px"}} width={50}  height={50} 
                      src={cap} alt="" />
                    Cap
                   </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-img-wrap">
                <img src={btc} alt="" />
              </div>
            </div>
          </div>
          {/* <div className="s-about-wrap">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6">
                <div className="s-about-title">
                  <h2 className="title">UNLEASH THE WARRIOR <span>INSIDE</span></h2>
                </div>
                <div className="s-about-content">
                  <p>Gaming is our passion. That’s why we have developed Phobos, a battle royale, Play2Earn multiplayer game with NFT-Skins and weapons in an interactive open-world map. Every character in the game has a unique skill set and hidden abilities unlocked by completing specific challenges during gameplay. As a player you get the chance to 1v1, 5v5, join tournaments and create value by forging, lending and holding Phobos NFT’s.</p>
                  <p>Our Vision is to develop modern High-End-Graphics games. Through this development, we aim to increase the value of our token ($KATA) Phobos for the community. The games will be integrated into an NFT Digital Art Marketplace via a decentralized Ecosystem. All In-game Items will be NFTs that are available in-game or options to sell in the open market which creates value for every penny spent. For More information please visit our <a href="/">Main Website</a> page and WHITEPAPER</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="s-about-img">
                  <img src="assets/img/images/about_img02.png" alt="" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className='card  ' style={{ margin: "0 auto" , marginTop: 10, marginBottom: 10}}>
          <h4>NFT Use Case</h4>
          <img src={usecase} alt=""  />
        </div> */}
      </section>
  )
}

export default AboutArea