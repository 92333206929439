import React,{ useEffect } from 'react'
import $ from 'jquery';

import logo  from '../assets/logo.jpg'

function Header() {
	 useEffect(()=>{
    
      //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>');
      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .main-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });
        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });

        //Menu Toggle Btn
        $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
          $('body').removeClass('mobile-menu-visible');
        });
      }
      $(".menu-tigger").on("click", function () {
        $(".extra-info,.offcanvas-overly").addClass("active");
        return false;
      });
      $(".menu-close,.offcanvas-overly").on("click", function () {
        $(".extra-info,.offcanvas-overly").removeClass("active");
      });

  },[]);
  return (
	 <header>
   <div className="header-top-area">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 m-auto">
            <div className="header-social justify-content-center">
              <span>Welcome back <a href="https://panel.nftcm.com/">Login</a></span>
                <ul>
                  {/* <li>
                    <a target="_blank" rel="noreferrer" href="/#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li> */}
      
   

                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div id="sticky-header" className="menu-area transparent-header">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler"><img src="assets/img/icon/menu.png" alt="" /></div>
                <div className="menu-wrap">
                  <nav className="menu-nav show">
                    <div className="logo">
                      <a href=""><img src={logo} className='w-20 h-20 rounded-full' alt="" /></a>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        <li className="active"><a href="/#banner">Home</a></li>
                        <li ><a href="/#slider">Slider</a></li>
                        <li><a href="/#about-us">Aboout US</a></li>
                        <li><a href="/#roadmap">use cases</a></li>
                        <li><a href="/#contact">CONTACT</a></li>
                        <li><a href="/#faq">FAQ</a></li>
                        
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul>
  
                        <li className="header-th"><a href="/#" className="menu-tigger">
                          <img src="assets/img/icon/menu.png" alt="" /></a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fa-solid fa-xmark" /></div>
                    <div className="nav-logo">
                    <a href="https://panel.nftcm.com/">
                      <img src={logo} alt="" className='w-20 h-20  rounded-full' />
                    </a>
                    </div>
                    <div className="menu-outer">
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className="header-phone">
                      <div className="call">
                        <span>  </span><a href="https://panel.nftcm.com" rel="noreferrer" >Login </a>
                      </div>
                    </div>
                    {/* <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="/#"><i className="fa-brands fa-twitter" /></a></li>
                        <li><a href="/#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="/#"><i className="fa-brands fa-twitch" /></a></li>
                        <li><a href="/#"><i className="fa-brands fa-instagram" /></a></li>
                        <li><a href="/#"><i className="fa-brands fa-youtube" /></a></li>
                      </ul>
                    </div> */}
                  </nav>
                </div>
                <div className="menu-backdrop" />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>

        <div className="extra-info">
          <div className="close-icon menu-close">
            <button><i className="fa-regular fa-rectangle-xmark" /></button>
          </div>
          <div className="side-logo mb-30">
            {/* <a href="/"><img src="assets/img/logo/logo.png" alt="Logo" /></a> */}
          </div>
          <div className="side-info mb-30">
            <div className="contact-list mb-30">
              <h4>Office Address</h4>
              <p>1384 Hawkcrest CV S  <br /> Tennessee, Cordova </p>
            </div>
            <div className="contact-list mb-30">
              <h4>Mail Address</h4>
              <p>support@nftcm.com</p>
            </div>
          </div>


        </div>
        <div className="offcanvas-overly" />
        {/* offCanvas-menu-end */}
      </header>
  )
}

export default Header