import React,{useEffect} from 'react'
import { gsap } from "gsap";
import {  ScrollTrigger } from "gsap/ScrollTrigger";

function Roadmap() {
	useEffect(()=>{
		gsap.registerPlugin(ScrollTrigger)
      // ScrollTrigger.create({
      //     trigger:'.partners-area',
      //     start:"bottom 80px",
      //     endTrigger:'.area-bg',
      //     end:'top bottom',
      //     pin:'.roadmap-area',
      //     pinSpacing:false,
      //     markers:true,
      // })
      ScrollTrigger.create({
          trigger:'.roadmap-head',
          start:"top 150px",
          endTrigger:'.area-bg',
          end:'top bottom',
          pin:'.roadmap-head',
          pinSpacing:false,
          onEnter:() =>{
              gsap.to('.roadmap-head .title', {
                  xPercent:-10,
                  fontSize:35
              })
          },
          // markers:true,
      })


      gsap.to(".timeline-img, .timeline-dots", {
          scrollTrigger: {
              trigger: ".timeline-img, .timeline-dots",
              scrub: true,
              start: "top 300px",
              endTrigger:'.area-bg',
              end: 'top bottom',
              onLeaveBack:() =>{
                  gsap.to('.roadmap-head .title', {
                      xPercent:0,
                      fontSize:60
                  })
              },
              // markers:true,
          },
          y: document.querySelector('.timeline-wrap').clientHeight - 410,
          duration:4,
          zIndex:-1
      })

      // gsap.to(".timeline-img, .timeline-dots", {
      //     scrollTrigger: {
      //         trigger: ".timeline-img, .timeline-dots",
      //         scrub: true,
      //         start: "top 300px",
      //         endTrigger:'.area-bg',
      //         end: 'top bottom',
      //         scroller:'.timeline',
              
      //     },
      //     y: document.querySelector('.timeline-wrap').clientHeight - 410,
      //     duration:4,
      //     zIndex:-1
      // })
	},[])
  return (
	<section id="roadmap" className="roadmap-area">
  
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title mb-55 roadmap-head">
                <h2 className="title">NFT <span> use cases</span></h2>
              </div>
            </div>
          </div>
          <div className="timeline">
            <div className="row justify-content-end">
              <div className="col-xl-8 col-lg-9 col-md-7">
                <div className="timeline-wrap">
                  
                  <div className="timeline-img"><img src="assets/img/images/timeline_img.png" alt="" /></div>
                  <div className="timeline-content">
                    <p>Our Goal is to create a Blockchain Game Ecosystem with Real-World utility and value. With this ambitious Project, we can reach the Moon! Our Team, Contributors and Investors have laid out Key Milestones that will put us on a Track to Success.</p>
                  </div>
                  <div className="timeline-item wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1.5s">
                    <div className="timeline-dots" />
                    <div className="timeline-list">
                      <h4 className="title">From sources across the web</h4>
                      <ul>
                        <li>Video game</li>
                        <li>Event ticketing</li>
                        <li>Fashion</li>
                        <li>Music</li>
                        <li>Supply chain management</li>
                        <li>Art and collectibles</li>
                        <li>Real Estate</li>
                        <li>Virtual real estate</li>
                        <li>Finance nfts</li>
                        <li>Digital identity</li>
                        <li>NFT staking</li>
                        <li>Art NFTs</li>
                        <li>Identity verification</li>
                        <li>Loan collateralization</li>
                        <li>Decentraland</li>
                        <li>Recent nft articles</li>
                        <li>Tokenization of assets</li>
                        <li>Digital collectibles</li>
                        <li>Gaming assets</li>
                        <li>Intellectual property protection</li>
                        <li>Luxury goods</li>
                        <li>NFTfi</li>
                        <li>Ticketmaster NFT tickets</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Roadmap