import React from 'react';
// import './FaqSection.css';

class FaqSection extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            data: [
                { 
                    id: 1, title: "What is an NFT meaning?", 
                    decscription: "Non-fungible tokens, often referred to as NFTs, are blockchain-based tokens that each represent a unique asset like a piece of art, digital content, or media. An NFT can be thought of as an irrevocable digital certificate of ownership and authenticity for a given asset, whether digital or physical."
                },
                { 
                    id: 2, title: "Is NFT a Crypto Currency?", 
                    decscription: "NFT stands for non-fungible token. These tokens are digital assets using the same basic technology that cryptocurrencies such as Bitcoin and Ethereum use to create digital scarcity. However, NFTs use digital scarcity in a different way than cryptocurrencies. Cryptocurrencies are fungible, whereas NFTs are non-fungible."
                },
                { 
                    id: 3, title: "How does NFT make money?", 
                    decscription: "The most direct way to make money with NFTs is by creating and selling them. As an artist or content creator, you can tokenize your work, turning it into a unique, tradeable asset on the blockchain. Creating NFTs for profit is easy — here's how you can get started: Choose your art medium."
                },
                { 
                    id: 4, title: "Is an NFT a currency?", 
                    decscription: "A non-fungible token (NFT) is a unique digital asset that represents ownership of real-world items like art, video clips, music, and more. NFTs use the same blockchain technology that powers cryptocurrencies, but they're not a currency."
                },
                { 
                    id: 5, title: "Is NFT a good investment?", 
                    decscription: "Investing in NFTs has just as many benefits as downsides. In general, NFTs should be purchased if the tokenized asset has an established market and interests you—otherwise, it's unlikely the NFT will experience price appreciation and that you'll be happy owning it following your purchase."
                },
                { 
                    id: 6, title: "How to convert NFT to money?", 
                    decscription: "If you're using a MetaMask wallet, simply click on the “Send” button and copy and paste the address of your crypto account. It will take a few minutes to receive the money in your crypto account. Once you have received the money, you can sell it on the crypto exchange you use to convert it to fiat."
                },
                { 
                    id: 7, title: "What is an example of NFT?", 
                    decscription: "Every NFT contains a digital signature which makes each one unique. NFTs are digital assets and could be photos, videos, audio files, or another digital format. NFT examples include artwork, comic books, sports collectibles, trading cards, games and more."
                },
                
                

            ]
        };
    }


    render() {
        return (
            <div id= "faq" className='py-5'>
            <div className='container'>
                <div className='row py-5'>
                    <div className='col-md-8 m-auto'>
                        <div className="section-title text-center py-5">
                            <h2 className="title text-capitalize">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='faq-area py-5'>
                <div className="shape shape-top" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 10" preserveAspectRatio="none">
                        <path d="M350,10L340,0h20L350,10z"></path>
                    </svg> 
                </div>
               <div className='container'>
                <div className="accordion" id="accordionExample">
                        {
                            this.state.data.map(val => {
                                return <div className="accordion-item" key={val.id}>
                                <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne">
                                    <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target={ '#collapse' + val.id} aria-expanded="true" aria-controls={'#collapse' + val.id}>
                                        { val.title }
                                    </button>
                                    {
                                        this.state.toggleAccordion ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                            <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                        </svg>
                                        : 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>
                                    }
                                </h2>
                                <div id={ 'collapse' + val.id} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        { val.decscription }
                                    </div>
                                </div>
                            </div>
                            })
                        }

                        {/* <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                When do we get our ETH allowances?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                The Ethereum rewards are automatically credited to the holder’s wallet after about 12 hours.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                Who are the team members?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                A group from the Space who have come together – 15 team members from Europe, who have already accompanied many projects and is well-rehearsed.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                Is there a buy and sell limit?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                After the launch: No, there is no sell or buy limit.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                                How much should the slippage fee be when buying/selling?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                The slippage fee is 10%. If this one doesn’t work out, feel free to try 11-15% slippage.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                                Can we do Katana Staking?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Currently we do not have this planned for the token, but we will have a NFT Staking.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header d-flex justify-content-between align-items-center" id="headingOne" onClick={this.handleToggle}>
                                <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                Where can we see the whitepaper?
                                </button>
                                {
                                    this.state.toggleAccordion ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                    </svg>
                                    : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="#ffffff" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                }
                            </h2>
                            <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                www.phobos.com/whitepaper
                                </div>
                            </div>
                        </div> */}
                    </div>
               </div>
               <div className='shape shape-bottom' data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 10" preserveAspectRatio="none">
                        <path d="M350,10L340,0h20L350,10z"></path>
                    </svg> 
                </div>
            </div>
            </div>
        )
    }
}

export default FaqSection
